/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomBreadCrumb from 'components/Breadcrumb'

// Third Party
import { Collapse } from 'react-bootstrap'
import styled from 'styled-components'

const StyledImg = styled(Img)`
  height: 100%;
  pointer-events: none;
  z-index: -1;
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 991px) {
    margin-top: -60px;
  }
`

const ImageContainer = styled.div`
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const Contact = ({ pageContext }) => {
  const { 
    faq: {
      title: pageTitle,
      path,
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        },
        common_questions: commonQuestions,
        swimming_questions: swimmingQuestions
      }
    },
    yoastMeta
  } = useStaticQuery(graphql`
    {
      faq: wordpressPage(wordpress_id: {eq: 170}) {
        title
        path
        acf {
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          common_questions {
            Answer
            question
          }
          swimming_questions {
            Answer
            question
          }
        }
        yoastMeta: yoast_meta {
          name
          content
          property
        }
      }
    }
`)

return (
  <Layout>
    <SEO yoast={{ meta: yoastMeta }} pathname={path} />
    <div className="faq">
      <ImageContainer>
        <StyledImg className="d-flex" alt="" fluid={bannerImage} />
        <ImageContainerText className="container">
          <div className="title font-size-xxl font-weight-xl">
            <h2 className="color-text-light">Veelgestelde vragen</h2>
          </div>
        </ImageContainerText>
      </ImageContainer>
      <CustomBreadCrumb data={pageTitle} className="py-2" pageContext={pageContext} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 pb-5d">
            <h2 className="font-size-l pt-3 pt-lg-5 font-weight-xl">Zwemles</h2>
            {swimmingQuestions.map((question, index) => (
              <Question key={index} id={`swimming${index}`} question={question} />
            ))}
          </div>
          <div className="col-12 col-lg-6">
            <h2 className="font-size-l pt-3 pt-lg-5 font-weight-xl">Algemene vragen ABC diploma</h2>
            {commonQuestions.map((question, index) => (
              <Question key={index} id={`common${index}`} question={question} />
            ))}
          </div>
        </div>
      </div>
      <div className="d-lg-block d-none empty-space-300" />
    </div>
  </Layout>
)
}

export default Contact

const StyledQuestion = styled.div`
  cursor: pointer;
`

const Question = ({ question, id }) => {
    const [open, setOpen] = useState(false)

    return (
      <StyledQuestion role="button" onClick={() => setOpen(!open)} aria-controls={id} aria-expanded={open} className="color-background-beige p-3 mb-3">
        <button type="button" className="d-flex w-100 justify-content-between">
          <h2 className="font-size-m font-weight-l color-text-contrast text-left mb-0">{question.question}</h2>
          <h2 className="font-size-m font-weight-l color-text-main mb-0">
            {open ? `-` : '+'}
          </h2>
        </button>
        <Collapse in={open}>
          <div id={id}>
            <div className="pl-1 pt-4" dangerouslySetInnerHTML={{ __html: question.Answer }} />
          </div>
        </Collapse>
      </StyledQuestion>
    )
}